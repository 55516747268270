<template>
  <div class="password">
    <div class="bread">
        <i class="el-icon-location"></i>
        <span>当前位置：修改密码</span>
    </div>
    <div class="pass">
        <div class="box">
            <div class="tit">修改密码</div>
            <div class="passbox">
                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="原密码" prop="age">
                        <el-input type="password" v-model="ruleForm.age"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="pass">
                        <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="确认新密码" prop="checkPass">
                        <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                        <el-button @click="resetForm('ruleForm')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import axios from 'axios';
export default {
    data(){
        var checkAge = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('原密码不能为空'));
            } else{
                callback();
            }
        };
        var validatePass = (rule, value, callback) => {
            if (value === '') {
            callback(new Error('请输入密码'));
            } else {
            if (this.ruleForm.checkPass !== '') {
                this.$refs.ruleForm.validateField('checkPass');
            }
            callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
            callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.pass) {
            callback(new Error('两次输入密码不一致!'));
            } else {
            callback();
            }
        };
        return{
            ruleForm: {
                pass: '',
                checkPass: '',
                age: ''
            },
            rules: {
                pass: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'blur' }
                ],
                age: [
                    // { required: true, message: '请输入原密码', trigger: 'blur' },
                    { validator: checkAge, trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // alert('submit!');
            // updatePassword({
            //     newPass:this.ruleForm.checkPass,
            //     lowPass:this.ruleForm.age
            // }).then(res=>{
            //     console.log(res);
            // })
            // console.log(this.$store.state.username);
            console.log(this.ruleForm.checkPass,this.ruleForm.age);
            axios.post('http://192.168.0.176:8881/admin/login/updatePassword', {
                    newpass:this.ruleForm.checkPass,
                    lowpass:this.ruleForm.age,
                    name:this.$store.state.username
                })
                .then((res) => {
                    Message.warning(res.data.msg);
                    if(res.data.msg == '操作成功'){
                        localStorage.removeItem("token");
                        this.$router.push({ path: '/login' })
                    }
                    console.log(res.data)
            });
            // console.log(this.ruleForm);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
}
</script>

<style  lang='less' scoped>
.password{
    height: 100%;
}
.bread{
    color: #606266;
    font-size: 14px;
    font-weight: 400;
    // color: #333333;
    line-height: 20px;
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    i{
        font-size: 20px;
        color: #56AAF7;
        padding-right: 10px;
    }
}
.pass {
  width: auto;
  min-height: 95%;
  background: #FFFFFF;
  box-shadow: 0px 3px 20px 0px rgba(131,131,131,0.1800);
  border-radius: 4px;
  padding: 24px;
  box-sizing: border-box;
  position: relative;
  .box{
    width: 500px;
    position: absolute;
    top: 100px;
    left: 50%;
    margin-left: -250px;
    .tit{
        line-height: 50px;
        font-weight: bold;
        padding-bottom: 20px;
    }
  }
}
</style>